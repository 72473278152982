// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-js": () => import("./../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-makeup-js": () => import("./../src/pages/makeup.js" /* webpackChunkName: "component---src-pages-makeup-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tattoos-js": () => import("./../src/pages/tattoos.js" /* webpackChunkName: "component---src-pages-tattoos-js" */)
}

